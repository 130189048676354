import React from "react"
import PageHeader from "../components/PageHeader";

const Bug = ({ issue, dateSolved }) => {

  return (
      <li>
        { dateSolved ?
            <>
              <b>{ dateSolved }</b> -
            </> : null
        }
        { dateSolved ? <span> { issue }</span> : issue }
      </li>
  )
}
const Bugs = () => {

  // const date = "Oct 22, 2020 - v. 0.1.011"
  const version = {
    DATE: "Oct 26, 2020",
    BUILD: "0.1.012",
  }

  return (
      <main>
        <PageHeader version={ version } title="Bugs" />

        <ul>

          <Bug dateSolved="FIXED - oct26.2020 (build 012)" issue="Sometimes moveElemental cannot move to an adjacent territory" />
          <Bug dateSolved="FIXED - oct22.2020 (build 011)" issue="MoveElemental button should be greyed out when there are no available elementals to move" />
          <Bug dateSolved="FIXED - oct22.2020 (build 011)" issue="MoveTile button should be greyed out when there are no available tiles to move (tiles occupied)" />
          <Bug dateSolved="FIXED - aug15.2020 (build 009)" issue="Can place multiple Elementals on one turn" />
          <Bug dateSolved="FIXED - july21.2020" issue="When moving a tile, clicking on StartTile breaks game" />
          <Bug dateSolved="FIXED - jun12.20" issue="Territory is counted again and again (should only Gain Influence on completion of a CIRCLE)" />
          <Bug dateSolved="FIXED" issue="Can pick up more than one tile at a time" />
          <Bug dateSolved="FIXED" issue="Too many HexCellLabels instantiated
            - must only instantiate new HexCellLabel when none already exists or destroy and re-instantiate" />

          <Bug dateSolved="FIXED" issue="Too many HexCells instantiated
            - must only instantiate new HexCell when none already exists or destroy and re-instantiate" />

        </ul>
      </main>
  )
}

export default Bugs