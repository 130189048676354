import React from "react"
import { useAuth0 } from '@auth0/auth0-react'

import Button from "react-bootstrap/Button"
import { isProduction } from "../../constants"

const LogInOutBtn = () => {

    const variant = "outline-secondary"

    const redirect_uri = isProduction ?
        "https://elemental-influence.soulski.ca/guidelines" :
        "http://localhost:3000/guidelines"

    const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
    const buttonText = ( isAuthenticated ) ? "Logout" : "Login"

    const handleClick = ( evt ) => {
        evt.preventDefault()

        if( isAuthenticated ) {
            logout()
        } else {
            loginWithRedirect({ redirectUri : redirect_uri})
        }
    }

    return (
        <Button
            variant={ variant }
            onClick={ ( evt ) => handleClick( evt ) }
        >
            { buttonText }
        </Button>
    )
}

export default LogInOutBtn