import React from "react"
import { Route, Redirect, Switch, useLocation } from "react-router-dom"

import Rules from "../pages/Rules"
import Overview from "../pages/Overview"
import Bugs from "../pages/Bugs"
import Progress from "../pages/Progress";
import PlaytestingGuidelines from "../pages/PlaytestingGuidelines";
import {useAuth0} from "@auth0/auth0-react";
import Downloads from "../pages/Downloads";

const Main = () => {

  const { isAuthenticated } = useAuth0()
  const location = useLocation();

  const pages = [
      '/rules',
      '/guidelines',
      '/bugs',
      '/progress',
      '/downloads'
  ]
  const redirect = pages.includes(location.pathname) ?
      null : <Redirect to="/" />;

  return (
    <Switch>

      <Route exact path="/">
        <Overview />
      </Route>

      <Route exact path="/rules">
        <Rules />
      </Route>

      { redirect }

      { isAuthenticated ? (
          <>
            <Route exact path="/guidelines">
              <PlaytestingGuidelines />
            </Route>

            <Route exact path="/bugs">
              <Bugs/>
            </Route>

            <Route exact path="/progress">
              <Progress />
            </Route>

            <Route exact path="/downloads">
              <Downloads />
            </Route>

            {/*{ !isProduction ? (*/}
            {/*    <Route exact path="/test">*/}
            {/*      <Test />*/}
            {/*    </Route>*/}
            {/*) : null }*/}

          </>
      ) : (
          <>
            <Route exact path="/guidelines">
              <Redirect to="/" />
            </Route>

            <Route exact path="/bugs">
              <Redirect to="/" />
            </Route>

            <Route exact path="/progress">
              <Redirect to="/" />
            </Route>
          </>
      )}


    </Switch>
  )
}

export default Main