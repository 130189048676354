import React from "react"

import NavBar from "./NavBar"
import Main from "./Main";
import Header from "./Header";
import {useAuth0} from "@auth0/auth0-react";
import Loading from "./Loading";

const Layout = () => {

  const { isLoading } = useAuth0()

  if( isLoading )
    return <Loading />
  else
    return (
      <>
        {/*<div className="header" >*/}
          <NavBar />
          <Header/>
        {/*</div>*/}
        <Main />
      </>
  )
}

export default Layout

