import React from "react"
import PropTypes from "prop-types"

const PageHeader = ({ title, version }) => {

  return (
      <div className="page-header">
        <hr />
        <h1>{ title }</h1>
        <small>
          { version.DATE }<br/>
          Version: <b>{ version.BUILD }</b>
        </small>
        <hr/>
      </div>
  )
}

PageHeader.propTypes = {

  title : PropTypes.string.isRequired,
  version: PropTypes.exact({
    DATE: PropTypes.string.isRequired,
    BUILD: PropTypes.string.isRequired
  })
}

export default PageHeader