import React from "react";
import Button from "react-bootstrap/Button";
import PageHeader from "../components/PageHeader";
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';


const Downloads = () => {

  const version = {
    DATE: "Oct 26, 2020",
    BUILD: "0.1.012",
  }
  const zipApp = "7-zip.org"

  return (
      <main>
        <PageHeader version={ version } title="Downloads"  />

        <h2><b>Build 012</b></h2>

        <div className="center">
          <h3>Windows 10<span> </span>
            <Button variant="outline-secondary" target="_blank" rel="noopener noreferrer"
                    href="https://drive.google.com/file/d/1cLA2Xsn2BMDrhMi8ZNAveJGp1jdXgMQy/view?usp=sharing" >
              <GetAppRoundedIcon />
            </Button>
          </h3>
        </div>
        <hr/>

        <p>
          The current build of the game is numbered and compressed in a <code>.7z</code> file (ie. <code>005.7z</code>).<br />
          It is a Windows build (I use 10, but 7/8 should work as well <em>though they are untested</em>).<br />
          You can download 7zip @ <a href={ `https://www.${ zipApp }` } target="_blank" rel="noopener noreferrer" >{ zipApp }</a>
        </p>
        <p>
          I can build for other platforms (macOS, Linux), <em>though they are untested</em>.
        </p>
      </main>
  )
}

export default Downloads