import React from "react"
import PageHeader from "../components/PageHeader";

const Progress = () => {

  const version = {
    DATE: "Oct 22, 2020",
    BUILD: "0.1.011",
  }

  return (
      <main>
        <PageHeader version={ version } title="Progress" />

        <h2>Gameplay</h2>
        <ul>

          <li>
            Player turn cycling is locked (hard-coded) with 3 players
            <ul>
              <li><b>Must develop GUI to determine # of players, playerNames, colors, etc.</b></li>
            </ul>
          </li>

          <li>
            <code>DrawTile</code> implemented
            <ul>
              <li>
                <code>PlaceTile</code> affects size of territory & occupying Elemental
              </li>
              <li><b>Must return removed Elementals to players</b></li>
            </ul>
          </li>

          <li><code>MoveTile</code> implemented</li>

          <li>
            <code>PlaceElemental</code> implemented
            <ul>
              <li>Can only place 1 on turn</li>
              <li>Can only place on own color</li>
              <li>Can only place if player has one available</li>
            </ul>
          </li>

          <li>
            <code>MoveElemental</code> implemented
            <ul>
              <li>Elemental can only move to neighboring territories</li>
              <li>Elemental cannot move to territory of different color that has a higher value</li>
              <li>Elemental moving to territory of same color will adopt that territory's value <b>if it is larger</b></li>
            </ul>
          </li>

          <li><b>Must implement <code>GameOver</code></b></li>
        </ul>
        <hr/>

        <h2>User Interface</h2>
        <ul>

          <li>Basic and functional</li>
          <li><b>UI will be entirely re-implemented once the gameplay (perhaps multiplayer) is complete</b></li>

        </ul>
        <hr />

        <h2>Multiplayer</h2>
        <ul>

          <li><b>Must implement</b></li>

        </ul>

      </main>
  )
}

export default Progress