import React from "react"
import {useAuth0} from "@auth0/auth0-react";

import { LinkContainer } from 'react-router-bootstrap'
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import LogInOutBtn from "./auth/LogInOutBtn";
// import {isProduction} from "../constants";

const NavBar = () => {

  const { isAuthenticated } = useAuth0()

  return (
    <Navbar sticky="top" bg="light" variant="light" expand="md" id="navbar">

      <LinkContainer to="/">
        <Navbar.Brand href="/">Elemental Influence</Navbar.Brand>
      </LinkContainer>

      <Navbar.Toggle aria-controls="main-navbar" />

      <Navbar.Collapse id="main-navbar">

        <Nav className="ml-auto">

          <LinkContainer to="/rules">
            <Nav.Link>
              Rules
            </Nav.Link>
          </LinkContainer>

          {/*{ !isProduction ? (*/}
          {/*    <LinkContainer to="/test">*/}
          {/*      <Nav.Link>*/}
          {/*        Test*/}
          {/*      </Nav.Link>*/}
          {/*    </LinkContainer>*/}
          {/*) : null }*/}

          { isAuthenticated ? (
              <>
                <LinkContainer to="/guidelines">
                  <Nav.Link>
                    Guidelines
                  </Nav.Link>
                </LinkContainer>

                <LinkContainer to="/progress">
                  <Nav.Link>
                    Progress
                  </Nav.Link>
                </LinkContainer>

                <LinkContainer to="/bugs">
                  <Nav.Link>
                    Bugs
                  </Nav.Link>
                </LinkContainer>

                <LinkContainer to="/downloads">
                  <Nav.Link>
                    Downloads
                  </Nav.Link>
                </LinkContainer>
              </>
          ) : null }

          {/*{ isAuthenticated ? (*/}
          {/*    <LinkContainer to="/progress">*/}
          {/*      <Nav.Link>*/}
          {/*        Progress*/}
          {/*      </Nav.Link>*/}
          {/*    </LinkContainer>*/}
          {/*) : null }*/}

          {/*{ isAuthenticated ? (*/}
          {/*    <LinkContainer to="/bugs">*/}
          {/*      <Nav.Link>*/}
          {/*        Bugs*/}
          {/*      </Nav.Link>*/}
          {/*    </LinkContainer>*/}
          {/*) : null }*/}

          <LogInOutBtn />

        </Nav>

      </Navbar.Collapse>

    </Navbar>
  )
}


export default NavBar