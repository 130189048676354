import React from "react"


const Rules = () => {

  const mr0 = "no-bottom-margin";
  return (
      <main>
        <div className="turn-order">
          <h2>THE ORDER OF PLAY</h2>
          <p>
            During their turn players must <strong><i>play</i></strong> or replay a tile
            and may <strong><i>place</i></strong> an Elemental onto the board
            and/or <strong><i>move</i></strong> an Elemental of their colour,
            then <strong><i>pass</i></strong> the tile bag to the next player.
          </p>

          <h3>To begin the game</h3>
          <p>
            Place the <strong>white tile</strong> in the centre of the table, this is the
            starting tile and it may be matched with any other tile.
            Starting with the first player, chosen by any agreed
            method, play proceeds clockwise as follows:
          </p>

          <h3>Each turn</h3>
          <ol>
            <li>
              You must <strong><i>Play</i></strong> a tile.<br/>
              Either:<br/>
              a) Draw and play a tile or<br/>
              strong) Select a <strong>free tile</strong> in play and replay it elsewhere
              <ul>
                <li>
                  Tiles must be placed such that all colours match each
                  other. Any colour may be matched with the <strong>white tile</strong>.
                </li>
                <li>
                  Connected colours form <strong>territories</strong> across tiles, defined
                  by their colour and <strong>size</strong>.
                </li>
                <li>
                  Any time you complete a <strong>single-colour hexagon</strong>, you
                  gain <strong>influence</strong> over that colour.
                </li>
                <li>
                  Remember, the game is over when the last tile is
                  played, so each tile drawn brings the game nearer to
                  the end!
                </li>
              </ul>
            </li>
            <li>
              You may <strong><i>Place</i></strong> an Elemental into an unoccupied territory
              of your colour, setting its level equal to the size of
              that territory.
            </li>
            <li>
              You may <strong><i>Move</i></strong> an Elemental you control:
              <ul>
                <li>
                  Into a neighbouring unoccupied territory of your
                  colour (setting the Elemental's level equal to the <strong>size </strong>
                  of the territory).
                </li>
                <li>
                  Into a neighbouring unoccupied territory of
                  another colour if the Elemental’s level is at least
                  equal to the size of the territory.
                </li>
                <li>
                  If an Elemental’s level is greater than that of an Elemental
                  in a neighbouring <strong>occupied territory</strong>, it may
                  devour that Elemental by moving into that territory,
                  gaining a level. The devoured Elemental is removed
                  from the board and returned to the player.
                </li>
              </ul>
            </li>
            <li>
              You must <strong><i>Pass</i></strong> the tile bag to the next player, it’s now
              their turn.
            </li>
          </ol>

          <h3>Endgame</h3>
          <p>
            The last turn of the game is the turn in which the last tile
            is played. At the end of that turn, players add up the total
            levels of all their Elementals on the board, the player with
            the greatest total is the winner.
          </p>
        </div>

        <div>
          <h2>GAME COMPONENTS</h2>
          <p>
            At the start of each game, players pick one of the 5
            colours, (R)ed, (B)lue, (G)reen, (Y)ellow or blac(K), and
            collect 5 dice of that colour. These dice are the playing
            pieces, called <strong>Elementals</strong>.
          </p>
          <p>
            The tile bag contains 50 hexagonal tiles, with 3 of the
            5 colours printed on each tile. Each colour appears on
            60% of all tiles, with 5 copies of each of the 10 different
            combinations of colours. These tiles are used to create
            the game board over the course of the game. Matching
            colours form <strong>territories</strong> which extend across tiles.
          </p>
          <p>
            Dice are used as Elementals, summoned into territories
            of your colour, which allow you to dominate the board,
            secure territories, devour other Elementals and ultimately
            win the game by having the greatest total level of Elemental
            pieces on the board after all tiles are played.
          </p>
          <div className="flex-container">
            <img src={require("../img/pieces_1.PNG")} alt="Elemental pieces" />
            <img src={require("../img/pieces_2.PNG")} alt="Tile pieces" />
          </div>
        </div>
        <div>
          <h2>TERMS AND DEFINITIONS</h2>

          <h3>Elementals</h3>
          <p>
            Elemental game pieces are represented using the dice.
            Each player has 5 dice of their chosen colour. These may
            be placed onto the board as Elementals, used to explore
            and control the board. The level of the Elemental is
            denoted using the dice number.
          </p>
          <ul>
            <li>
              If, at any time, an Elemental occupies a territory of its
              colour, set the level of that Elemental to the size of
              the territory it occupies.
            </li>
            <li>
              An Elemental's level must always remain between 1
              and 6. Increasing or decreasing the level outside of
              this range has no effect.
            </li>
            <li>
              If, at any time, an Elemental occupies a territory of
              another colour whose size grows to be greater than
              the Elemental’s level, it is removed from the board
              and returned to the player.
            </li>
            <li>
              If, at any time, an Elemental occupies a territory of 7
              or more, regardless of colour, it is removed from the
              board and returned to the player.
            </li>
            <li>
              Only 1 Elemental may occupy a single territory. If a
              tile is played that causes 2 occupied territories to
              become connected, both Elementals are removed
              from the board and returned to the player(s).
            </li>
          </ul>

          <h3>Territory</h3>
          <p>
            A connected zone of the same colour. Elementals occupy
            the entire territory, not just the tile they are on.
          </p>
          <h3>Size</h3>
          <p>
            The size of a territory is equal to the number of
            connected tiles that form the territory
          </p>

          <h3>Influence</h3>
          <p className={ mr0 }>
            Players gain influence over a colour in two ways:
          </p>
          <ol>
            <li>Completing a <strong>single-colour hexagon</strong></li>
            <li>Sacrificing one of their pieces on the white tile at the start of their turn</li>
          </ol>

          <p className={ mr0 }>
            When a player gains influence over a colour, they may
            select an Elemental of that colour, or an Elemental
            occupying a territory of that colour, and either:
          </p>

          <ul>
            <li>
              Increase or decrease its level by one (if it occupies a
              territory of a different colour) or
            </li>
            <li>Make a legitimate move with it.</li>
          </ul>

          <h3>Occupied Territory</h3>
          <p>A territory that currently contains an Elemental.</p>

          <h3>Free Tile</h3>
          <p>
            A tile that neither makes up a part of an <strong>occupied
            territory</strong> nor solely connects other tiles to the board (all
            tiles must remain connected together).
          </p>

          <h3>Single-Colour Hexagon</h3>
          <p>
            A solid, single-colour hexagon territory
            formed by 3 separate tiles. Whenever a
            player completes a single-colour hexagon
            they gain influence over that colour.
          </p>

          <h3>White Tile (neutral ground)</h3>
          <p className={ mr0 }>
            The single white tile serves as the starting point of the
            game and as 'neutral ground' during the game.
          </p>

          <ul>
            <li>Any tile may be matched with the white tile, regardless of colour.</li>
            <li>An Elemental may not be devoured while it occupies the white tile.</li>
            <li>
              If a player has an Elemental occupying the white tile
              at the start of their turn they must sacrifice it,
              removing it from the board and gaining <strong>influence</strong> over the colour of their choice.
            </li>
          </ul>
        </div>
        <div>
          <h2>VARIANTS</h2>
          <ul>
            <li>
              Want to play a shorter game? Simply place fewer
              copies of each tile in the bag at the start of the
              game. Make sure to use an equal number of each!
            </li>
            <li>
              If playing with less that 5 players, a colour may be
              chosen as a ‘neutral’ territory that benefits no player.
            </li>
            <li>
              If playing with less than 5 players, removing all
              instances of a single colour from the game tiles will
              reduce the total maximum number of tiles down
              to 20, providing much shorter and more cutthroat
              gameplay.
            </li>
          </ul>
        </div>
      </main>
  )
}


export default Rules