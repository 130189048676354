import React from "react"
import PageHeader from "../components/PageHeader";
import {Link} from "react-router-dom";

const PlaytestingGuidelines = () => {

  const version = {
    DATE: "Aug 16, 2020",
    BUILD: "0.1.009",
  }

  return (
      <main>
        <PageHeader title="Playtesting Guidelines" version={ version } />

        <p>Download the new build @  <Link to="/downloads">the Download page</Link></p>
        <p>
          Playtesting is about squashing bugs, identifying problems in design, developing gameplay and ensuring a solid user experience.
        </p>
        <p><b>Please consult the <Link to="/progress" >Progress page</Link> to get a sense of where the development is currently at.</b></p>
        <hr />

        <h2>Bugs</h2>
        <p>
          I keep a list of bugs that I am aware of @ <Link to="/bugs" >my Bugs page</Link>. Check this first.
        </p>
        <p>To properly address bugs, I need to recreate them myself. So what I need to do that is:</p>
        <ul>

          <li>the steps you took to discover the bug</li>
          <li>
            a debug log file
            <ul>
              <li><em>this is only generated if you tell the game to do so (it slows the game down some)</em></li>
              <li>set the <code>[Write Debug Logs to File]</code> toggle in the <code>[Options]</code> menu on the start screen</li>
              <li>
                send me the latest (I assume) <code>.html</code> file located @<br />
                <code><b>[GAME_LOCATION]</b>\DebugLogs\log_<b>[DATE_STAMP]</b>.html</code><br/>
                (ie. <code>c:\ElementalInfluence\DebugLogs\log_2020-07-24_13-30-13.html</code>)
              </li>
            </ul>
          </li>
          <li>optionally a screenshot of the game when things went wrong</li>

        </ul>


      </main>
  )
}

export default PlaytestingGuidelines