import React from "react"

const Header = () => {

  const email = "elemental.influence@gmail.com";

  return (
      <header>
        <p className="header-text" style={{ display: `flex`, justifyContent: `space-between`, alignItems: `baseline` }}>
          {/*<small>by Leif Olson</small>*/}
          <b>
            <a href={`mailto:${email}`}>{ email }</a>
          </b>
          2018.05.31
        </p>
     </header>
  )
}

export default Header