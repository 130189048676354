import React, {useEffect} from "react"

const Overview = () => {

  const navbarClasslist = {
    custom : `navbar navbar-expand-md navbar-dark bg-dark sticky-top`,
    default: `navbar navbar-expand-md navbar-light bg-light sticky-top`,
  }

  useEffect(() => {
    document.body.id = "overview"
    document.getElementById("navbar").classList = navbarClasslist.custom
    return function () {
      document.body.id = ""
      document.getElementById("navbar").classList = navbarClasslist.default
    }
  })

  return (
      <main>
        <img src={require("../img/main.PNG")} alt="Game in play" className="center" />
        <p>
          Elemental Influence is a 3-5 player, hexagon-tile based
          game: A blend of dominos meets checkers meets civilization.
          A simple, portable game that starts quickly and cleans
          up easily into two small bags for tiles and dice. With games
          lasting 1-2 hours, this approachable and nuanced strategy
          game rewards diplomacy, long-term planning, and spur-of-the-moment
          decisions to produce a dynamic and balanced
          experience.
        </p>
        <p>
          Players draw and match tri-coloured tiles to create a different
          board for every game, summoning Elemental dice
          that grow in strength as they control the board. Early game
          dominance can lead to victory, but long-term strategy may
          produce dramatic changes. Five colours and up to 5 players
          provide frequent opportunities to build and break alliances.
        </p>
        <p>
          Players compete to create a favourable game board, playing
          and replaying tiles, gaining influence over the Elementals on
          the board -- growing in power to control the greatest army
          at the time the last tile is played!
        </p>
        <h2>GAMEPLAY OVERVIEW</h2>
        <p>
          The game consists of 1 white tile, 50 tri-coloured tiles which
          form the board, and 5 sets of 5 coloured dice, used as your
          Elemental game pieces. Elemental dice increase in level as
          you expand territories of your colour. Your goal during the
          game is to control the largest total level of Elementals on the
          board by the time the last tile is played, while preventing your
          opponents from doing the same.
        </p>
        <p>
          Players take turns playing tiles, placing dice, moving their
          Elementals and influencing the game, achieving victory by
          controlling the greatest total levels of Elementals at the time
          the last tile is played.
        </p>
        <h2>HOW TO PLAY</h2>
        <p>
          Each player chooses a colour (Red, Green, Blue, Yellow,
          Black) and the 5 dice of their colour. These dice are used as
          Elementals. Starting from the white tile, players draw and play
          tiles each turn, creating a unique board every game. Each
          tile has 3 of the 5 colours, printed in reverse on the opposite
          side. Tiles must be played to match the colours already on the
          board, creating zones of colour or territories extending across
          tiles.
        </p>
        <p>
          Each turn, players summon Elemental dice onto territories
          of their colour to explore and control the board. The number
          on the die represents the level of the Elemental, which can
          be increased by occupying larger territories or devouring
          weaker elementals. Players work to increase and secure their
          Elementals to create the most powerful army by the end of
          the game!
        </p>
        <p>
          Completing solid single-colour hexagons grants temporary
          influence over an Elemental of that colour, or an elemental
          occupying a territory of that colour, moving them or altering
          their level. Players have the choice to help themselves or
          hinder the plans of others.
        </p>
        <p>
          Elementals are leveled up by occupying larger territories of
          their colour, granting them more dominance on the board, or
          by devouring smaller elementals, growing in strength while
          temporarily frustrating other players.
        </p>
        <p>
          Any time an Elemental occupies a territory of its colour, its
          level is set equal to the size of that territory! Large territories
          become sources of power, generating high-level Elementals.
          Small territories may act as refuge against stronger Elementals.
          If an Elemental occupies a smaller sized territory of its
          colour, it will be reduced to that level, so players must be cautious
          when moving their Elementals across the board. Gaining
          influence at the right time can allow players to bring down
          even high-level Elementals.
        </p>
        <p>
          An Elemental may devour those of lower levels, growing in
          strength. But unexpected shifts in the board or well-timed
          influence can turn the tables on those in the lead. Territories
          must be defended! Players may replay ‘free’ tiles to new
          locations on the board, breaking large territories into smaller
          fragments. Players have a constant choice: whether to hunt
          down other Elementals and devour them or to secure their
          fortress and preserve their powerful army.
        </p>

        <img src={require("../img/main_2.PNG")} alt="Game in play" className="center"/>
        <hr/>
        <p>
          With heartfelt thanks to all the playtesters
          and editors that helped to bring this game
          into being!
        </p>
        <p>
          Sheena and the Lamberts, Darren, Julie,
          Adrian, James, Andi, Riley, Serena, Adam,
          Eben, Caroline, Andrea and many more...
        </p>

      </main>
  )
}

export default Overview